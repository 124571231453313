import { createRouter, createWebHistory } from 'vue-router'

const routes = [
    {
      path: '/',
      name: 'MainLayout',
      component: () => import('../components/templates/main/MainLayout.vue'),
      children: [
          {
              name: 'HomeView',
              path: '',
              component: () => import('../views/HomeView.vue')
          },
      ]
    },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior(to, from, savedPosition) {
      return { top: 0 }
    },
  routes
})

export default router
